'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:UiHelpers

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'UiHelpers', [
    '$mdMedia'
    '$mdDialog'
    ($mdMedia, $mdDialog) ->
      UiHelpersBase = {}

      UiHelpersBase.promptSelection = (options = {}) ->
        defaults =
          allowCancel: true
          title: 'app.select-value'
          selected: null
          choices:
            foo: 'Foo'
            bar: 'Bar'
          onSelect: (result) ->
          onCancel: () ->

        options = angular.extend {}, defaults, options

        $mdDialog
          .show
            controllerAs: 'selection'
            templateUrl: 'mundo-utils/views/ui-helpers/selection-dialog.tpl.html'
            parent: angular.element document.body
            clickOutsideToClose: options.allowCancel
            fullscreen: ($mdMedia 'sm') || ($mdMedia 'xs')
            resolve:
              options: () ->
                options.choices
              selected: () ->
                options.selected
              allowCancel: () ->
                options.allowCancel
              title: () ->
                options.title
            controller: [
              '$scope'
              '$mdDialog'
              'options'
              'selected'
              'allowCancel'
              'title'
              ($scope, $mdDialog, options, selected, allowCancel, title) ->
                $scope.options = options
                $scope.selected = selected
                $scope.allowCancel = allowCancel
                $scope.title = title

                $scope.hide = () ->
                  $mdDialog.hide()

                $scope.cancel = () ->
                  $mdDialog.cancel()

                $scope.submit = () ->
                  $mdDialog.hide $scope.selected
            ]
          .then (result) ->
            return options.onSelect result
          , () ->
            return options.onCancel()
            # Cancel

      UiHelpersBase.promptInput = (options = {}) ->
        defaults =
          allowCancel: true
          title: 'app.input-value'
          placeholder: ''
          value: ''
          onInput: (result) ->
          onCancel: () ->

        options = angular.extend {}, defaults, options

        $mdDialog
          .show
            controllerAs: 'input'
            templateUrl: 'mundo-utils/views/ui-helpers/input-dialog.tpl.html'
            parent: angular.element document.body
            clickOutsideToClose: options.allowCancel
            fullscreen: ($mdMedia 'sm') || ($mdMedia 'xs')
            resolve:
              placeholder: () ->
                options.placeholder
              value: () ->
                options.value
              allowCancel: () ->
                options.allowCancel
              title: () ->
                options.title
            controller: [
              '$scope'
              '$mdDialog'
              'placeholder'
              'value'
              'allowCancel'
              'title'
              ($scope, $mdDialog, placeholder, value, allowCancel, title) ->
                $scope.placeholder = placeholder
                $scope.value = value
                $scope.allowCancel = allowCancel
                $scope.title = title

                $scope.hide = () ->
                  $mdDialog.hide()

                $scope.cancel = () ->
                  $mdDialog.cancel()

                $scope.submit = () ->
                  $mdDialog.hide $scope.value
            ]
          .then (result) ->
            return options.onInput result
          , () ->
            return options.onCancel()
            # Cancel

      UiHelpersBase
  ]
